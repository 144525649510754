<template>
<fragment>
    <loading v-if="isLoading"/>
    <div v-else>
        <div v-if="errorMessage!==null">{{ errorMessage }}</div>
        <login
            :profile="userProfile"
            :shop="displayShop"
            @onNext="pageType = 'HOME'"
        />
    </div>
</fragment>
</template>

<script>
import Login from '@/components/staff/Login'

import { requestShopInfo } from '@/apis/shop'

export default {
    components: {
        Login,
    },
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("STAFF_PROFILE_DATA")
        return {
            userProfile : profile || {},
            pageType: null,
            isLoading : false ,
            errorMessage : null,
            displayShop: {},
        }
    },
    methods: {
        async onLoad() {
            this.isLoading = true
            await requestShopInfo({
                bot_id: this.userProfile.bot_id,
                mid: this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayShop = res.data.data
                    this.isLoading = false
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
        }
    },
    mounted() {
        this.onLoad()
    }
}
</script>