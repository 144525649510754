<template>
<fragment>
    <loading v-if="isLoading"/>
    <div class="content">
        <div class="bg"></div>
        <div class="header">
            <div class="d-flex my-2 align-items-center">
                <div class="">
                    <img :src="shop.shop_img_url ? shop.shop_img_url : defaultImgLogo" class="rounded-circle" width="56" height="56" style="object-fit: cover;">
                </div>
                <div class=" pl-3 pr-1 ">
                    <div class="fc-1 tx-20 fw-500 cuttext">{{shop.shop_name ? shop.shop_name : 'STORE NAME'}}</div>
                </div>
            </div>
        </div>
        <div class="px-4" id="form-login" v-if="!formData.login_completed"> 
            <div class="px-3 d-flex justify-content-center box-input">
                <div class="align-self-center">
                    <div class="form-group mb-5">
                        <label>User name</label>
                        <input
                            type="tel"
                            class="form-control form-custom-2"
                            v-model="formData.username"
                            :class="{'is-invalid': formData.form_dirty && !formData.username_valid}"
                            @keypress="onNumberInput"
                        >
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input
                            type="tel"
                            class="form-control form-custom-2"
                            v-model="formData.password"
                            :class="{'is-invalid': formData.form_dirty && !formData.password_valid}"
                            @keypress="onNumberInput"
                        >
                    </div>
                    <div v-if="formData.form_dirty && !formData.password_valid" class="tx-18" style="color: #FF0000 ">ข้อมูลไม่ถูกต้อง</div>
                </div>
            </div>
            <div class="bottom px-3 my-3">
                <div class="text-center tx-14 lh-21p fc-1 mb-2">
                    <div>Power by: Yellow Idea Co., Ltd</div>
                    <div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
                </div>
                <div class="text-center mb-3">
                    <img src="@/assets/images/logo.png" width="111">
                </div>
                <div class="text-center pb-2">
                    <button class="btn btn-login w-100" :disabled="!formData.is_valid" @click="loginClick">เข้าสู่ระบบ</button>
                </div>
            </div>

        </div>
        <div v-else>
            <div class="px-3 d-flex justify-content-center box-input">
				<div class="align-self-center text-center">
					<img :src="profile.display_image ? profile.display_image : defaultImg" class="rounded-circle mb-3" width="100" height="100" style="object-fit: cover;">
					<div class="fc-1 tx-14 mb-1">สวัสดี</div>
					<div class="fc-1 tx-18 fw-500 mb-3">{{displayStaffProfile.first_name ? displayStaffProfile.first_name :'[First Name]'}}  {{displayStaffProfile.last_name ? displayStaffProfile.last_name :'[Last Name]'}}</div>
					<div class="fc-3 tx-29">ลงทะเบียน เรียบร้อยแล้ว</div>
				</div>
			</div>
			<div class="bottom px-3 my-3">
				<div class="text-center tx-14 lh-21p fc-1 mb-2">
					<div>Power by: Yellow Idea Co., Ltd</div>
					<div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
				</div>
				<div class="text-center mb-3">
					<img src="@/assets/images/logo.png" width="111">
				</div>
				<div class="text-center pb-2">
					<button type="button" class="btn btn-login w-100" @click="onClose">ปิดหน้าต่างนี้</button>
				</div>
			</div>
        </div>
    </div>
    <!-- Modal Incorrect username or password -->
    <div class="modal fade" id="modal-error" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-3 mt-3">Username/Password ไม่ถูกต้อง</div>
                    <div class="fc-1 tx-18 text-center mb-3 mt-3">กรุณาลองใหม่อีกครั้ง</div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ตกลง</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fragment>
</template>

<script>
import { requestStaffLogin, requestStaffInfo } from '@/apis/staff'
import { requestLogActivity } from '@/apis/apis'

export default {
    props: ['profile', 'shop'],
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("STAFF_PROFILE_DATA")
        return {
            isLoading: false,
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
            userProfile : profile || {},
            displayStaffProfile: {},
            formData: {
                form_dirty: false,
                is_valid: false,
                username: '',
                password: '',
                login_completed: false,
                username_valid: false,
                password_valid: false,
            }
        }
    },
    mounted() {
        this.logActivity('Login')
    },
    updated() {
        if(this.formData.login_completed) {
            this.logActivity('Login complete')
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.username ||
                    !val.password
                ) {
                    this.formData.is_valid = false
                }
                else {
                    this.formData.is_valid = true
                }
            },
            deep: true
        },
    },
    methods: {
        async loginClick() {
            this.isLoading = true
            await requestStaffLogin({
                bot_id: this.profile.bot_id,
                formData : {
                    username: this.formData.username,
                    password: this.formData.password,
                    id: this.profile.code,
                    mid: this.profile.mid,
                } ,
                mid : this.profile.mid,
                role: 'employee',
                cbSuccess : res => {
                    if(res.data.status === "LOGIN_ERROR" && res.data.reason === "Incorrect username or password") {
                        this.isLoading = false
                        window.$('#modal-error').modal()
                    }
                    else {
                        this.formData.login_completed = true
                        this.onLoad()
                        this.isLoading = false
                    }
                } ,
                cbError : () => {
                    this.formData.form_dirty = true
                    this.formData.username_valid = false
                    this.formData.password_valid = false
                    this.isLoading = false
                }
            })
        },
        onClose() {
            // eslint-disable-next-line no-undef
            liff.closeWindow()
        },
        onNumberInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        async onLoad() {
            this.isLoading = true
            await requestStaffInfo({
                bot_id: this.userProfile.bot_id,
                mid : this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayStaffProfile = res.data.data
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
        },
        async logActivity(activity) {
            await requestLogActivity({
                formData: {
                    mid: this.userProfile.mid,
                    bot_id: this.userProfile.bot_id,
                    activity,
                },
                role: 'user',
                cbSuccess : () => {} ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        }
    },
}
</script>