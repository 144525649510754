import { service } from "./request-index"
import { getToken } from "../utility/help"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestShopInfo = ({ bot_id, mid, role, cbSuccess , cbError }) => requestAll(
    service.get(`/api/v1/${bot_id}/shop-profile`,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/shop-profile`)
            }
        }
    ),
    { cbSuccess , cbError }
)

export const requestCouponList = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/coupon/list`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/coupon/list`, formData)
            }
        }
    ),
    { cbSuccess , cbError }
)

export const requestCouponInfo = ({ bot_id, id, mid, role, cbSuccess , cbError }) => requestAll(
    service.get(`/api/v1/${bot_id}/coupon/${id}`,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/coupon/${id}`)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestItemsList = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/product/list`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/product/list`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)
